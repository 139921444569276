// BuildingInfo.js

import React, { useEffect, Fragment} from 'react';
import { useParams } from 'react-router-dom';
import useBuildingData from '../commands/useBuildingData';
import Footer from '../layout/Footer';
import Header from '../layout/Header';
import FlatList from '../flat/FlatList';
import { Grid } from '@mui/material';
import BuildingHeader from './BuildingHeader';
import DeviceList from '../device/DeviceList';
import { TailSpin } from 'react-loader-spinner';

export default function BuildingInfo() {
  
  const { buildingId } = useParams();
  const { buildingData, fetchBuildingData } = useBuildingData(buildingId);

  const refreshBuildingData = () => {
    fetchBuildingData(buildingId, true);
  };

  useEffect(() => {
    //check if query string contains refreshFlag=true
    const urlParams = new URLSearchParams(window.location.search);
    const refreshFlag = urlParams.get('refreshFlag');
    if (refreshFlag === 'true') {
      console.log('refreshFlag found, refreshing building data');
      refreshBuildingData();
      urlParams.delete('refreshFlag');
    }
  }, []);

  return (
    <Fragment>
    <Header/>
    {
    (buildingData !== null && buildingData !== undefined) ? 
      <Fragment>
        <BuildingHeader buildingData={buildingData} buildingId={buildingId} refreshData={() => {refreshBuildingData();}}/>
        <Grid container justifyContent="center" height="100vh">
        <Grid container spacing={{xs:0, sm:3}} marginX={{xs:0, sm:0}} sx={{paddingBottom:"80px"}} marginTop={{xs:0, sm:2}} maxWidth={{xs:'100%', sm:'80%'}}>
          <Grid item xs={12} sm={6} justifyContent={{xs:"center", sm:"left"}} sx={{ marginTop:{xs:'10px', sm:'10px'} }} >
            <FlatList flatinfo={buildingData.flats} buildingId={buildingId} setRefresh={() => {
                  refreshBuildingData();
                }} />
          </Grid>
          <Grid item xs={12} sm={6}  justifyContent={{xs:"center", sm:"right"}} sx={{ marginTop:{xs:'10px', sm:'10px'}}}>
              <DeviceList devicesinfo={buildingData.flats[0].devices} buildingId={buildingId} flatId={0} setRefresh={() => {
                  refreshBuildingData();
                }} />
          </Grid>
        </Grid>
        </Grid>
        <Footer />
      </Fragment>
    :
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%', height: '80vh', alignItems:'center'}}>
      <TailSpin type="TailSpin" color="#00BFFF" height={80} width={80} marginTop={5}/>
    </div>
    }
    </Fragment>
    ); 
}