import { useEffect, useState, useContext, Fragment } from "react";
import { useParams } from "react-router-dom";
import useBuildingData from "../commands/useBuildingData";
import Header from "../layout/Header";
import BuildingHeader from "../building/BuildingHeader";
import Footer from "../layout/Footer";
import { Screenshot, SmartScreen, Cancel,Delete,Send,Edit, PhoneIphone, RestartAlt, Upgrade,LocalPolice } from "@mui/icons-material";
import {Grid, Typography, Button, TextField } from "@mui/material";
import Confirmation from "../inputs/Confirmation";
import { AuthContext} from "../authentication/AuthProvider";
import { toast } from "react-toastify";
import MacAddressInput from "../inputs/MacAddressInput";
import { useNavigate } from 'react-router-dom';
import PhoneDevice from "./PhoneDevice";
import {TailSpin} from "react-loader-spinner";

function DeviceInfo() {
    const { buildingId, flatId, deviceId } = useParams();
    const [device, setDevice] = useState(null);
    const [initialDevice, setInitialDevice] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [deleteButtonText, setDeleteButtonText] = useState("מחיקה");
    const [editButtonText, setEditButtonText] = useState("עדכון");
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openRestartDialog, setOpenRestartDialog] = useState(false);
    const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false);
    const [deviceIdText, setDeviceIdText] = useState('');
    const [doorCode, setDoorCode] = useState('');
    const [doorCodeError, setDoorCodeError] = useState(false);
    const {requestHeaders, isAdmin} = useContext(AuthContext);
    const [displayName, setDisplayName] = useState('');
    const [phone, setPhone] = useState('');
    const [connected, setConnected] = useState(null);
    const [appVersion, setAppVersion] = useState(null);
    const [gotDeviceStatus, setGotDeviceStatus] = useState(false);
    const { buildingData, fetchBuildingData } = useBuildingData(buildingId);

    const navigate = useNavigate();
    useEffect(() => {
        if (buildingData === null || buildingData.flats === null) {
            return;
        }
        const flat = buildingData.flats.find(flat => flat.flatid === flatId);
        const device = flat.devices.find(device => device.deviceid === deviceId);
        for (const device of flat.devices) {
            if (device.deviceid === deviceId) {
              setInitialDevice(device);
              setDeviceIdText(deviceId);
              setDoorCode(device.doorcode);
              setDevice(device);
              setDisplayName(device.displayName);
              return;
            }
        }
        setDevice(device);
    }, [buildingData]);

    
    useEffect(() => {
      const fetchDeviceStatus = async () => {
          if (device && device.deviceid && device.devicetype !== 'phone') {
              const targetServer = process.env.REACT_APP_API_HOST;
              const api = `api/devices/${device.deviceid}?devicetype=${device.devicetype}`;
              try {
                  const response = await fetch(targetServer + api, {
                      method: 'GET',
                      headers: requestHeaders
                  });
                  const data = await response.json();
                  setConnected(data.connected);
                  setAppVersion(data.appVersion);
                  setGotDeviceStatus(true);
              } catch (error) {
                  console.error('Error fetching device status:', error);
                  setConnected(false);
                  setAppVersion(null);
                  setGotDeviceStatus(true);
              }
          }
      };

      fetchDeviceStatus();
  }, [device, requestHeaders]);

  const refreshData = () => {
    fetchBuildingData(buildingId,true); // Force refresh
  };


    const resetEditFields = () => {
      setDevice(initialDevice);
      setDeviceIdText(initialDevice.deviceid);
      setDoorCode(initialDevice.doorcode);
      setDisplayName(initialDevice.displayName);
    }
    
    const confirmDelete = () => {
      //if in edit mode, cancel edit mode
      if(editMode){
        resetEditFields();
        exitEditMode();
      }
      else //user requested to delete, show confirm dialog
      {
        setOpenDeleteDialog(true);
      }
    };

    const cancelDelete = () => {
      setOpenDeleteDialog(false);
    };



    const cancelUpgrade = () => {
      setOpenUpgradeDialog(false);
    };

    const DeleteDevice = async () => {
        const targetServer = process.env.REACT_APP_API_HOST

        const api = 'api/building/'+buildingId+'/'+flatId+'/deletedevice/';
        const request = {deviceid:deviceId};
        try {
          const response = await fetch(targetServer + api ,  {
            method: 'DELETE',
            headers: requestHeaders,
            body: JSON.stringify(request)
          });
          const data = await response.json();
          console.log('Delete building sucessful! response:', data);
        } catch (error) {
          console.error(error);
          toast.error('שגיאה במחיקת מכשיר');
        
        }
        refreshData();

        
        if(flatId==='0') //navigate to building page
        {
          navigate(`/building/${buildingId}?refreshFlag=true`);
        }
        else{
          console.log('navigating back to flat page, indicating that refresh is needed');
          //navigate to flat page
          navigate(`/building/${buildingId}/${flatId}?refreshFlag=true`);
        }
    };

    const updateDevice = async () => {
        const targetServer = process.env.REACT_APP_API_HOST
        const newDevice = {
            name: displayName
        };
        if(device.devicetype==='base'){
          newDevice.doorcode = doorCode;
        }
        if(device.devicetype==='phone'){
          newDevice.phone = phone;
        }
        const api = 'api/building/'+buildingId+'/'+flatId + '/' + deviceId;
        try {
          await fetch(targetServer + api ,  {
            method: 'PUT',
            headers: requestHeaders,
            body: JSON.stringify(newDevice)
        });
        console.log('Update building sucessful! response:');
          refreshData();
          toast.success('מכשיר עודכן בהצלחה');
        } catch (error) {
          console.error(error);
          resetEditFields();
          toast.error('שגיאה בעדכון מכשיר');
        }
    };

    const  enterEditMode = () => {
        if(editMode){
            updateDevice();
            exitEditMode();

        }
        else{
            setEditMode(true);
            setDeleteButtonText("ביטול");
            setEditButtonText("שמירה");
        }
    };

    const exitEditMode=()=>{
        setEditMode(false);
        setDeleteButtonText("מחיקה");
        setEditButtonText("עדכון");
    };


    const handleDoorCodeChange = (event) => {
      const { value } = event.target;
      //check if doorcode is 4 digits
      const doorcodeRegex = /^\d{4}$/;
      if(doorcodeRegex.test(value) || value === ''){
        setDoorCodeError(false);
      }
      else{
        setDoorCodeError(true);
      }
      setDoorCode(value);

    };

    const handlePhoneChanged = (p => {
        setPhone(p.phone);
    });

    const confirmRestart = () => {
      setOpenRestartDialog(true);
     };

     const confirmUpgrade = () => {
      setOpenUpgradeDialog(true);
     };

    const cancelRestart = () => {
      setOpenRestartDialog(false);
    };

    const handleRestart = async () => {
      const targetServer = process.env.REACT_APP_API_HOST;
      const api = 'api/callPanel';
      const request_body =  {
          "buildingId":buildingId,
          "deviceId":deviceId,
          "action":"reboot"}
      const request = {
        method: 'POST',
        deviceid: deviceId,
        headers: requestHeaders,
        action: 'reboot',
        body: JSON.stringify(request_body)
      }

      try{
        const response = await fetch(targetServer + api , request);
        const data = await response.json();
        console.log('Reboot device sucessful! response:', data);
        toast.success('בקשה לאתחול מכשיר נשלחה בהצלחה');
      }
      catch (error) {
        console.error(error);
        toast.error('שגיאה באתחול מכשיר');
      };
    };

      const handleUpgrade = async () => { 
        const targetServer = process.env.REACT_APP_API_HOST
        const api = 'api/building/'+buildingId+'/'+flatId+'/'+deviceId+'/upgrade/';
        const request_body =  {};
        try {
          const response = await fetch(targetServer + api ,  {
            method: 'POST',
            headers: requestHeaders,
            body: JSON.stringify(request_body)
          });
          const data = await response.json();
          console.log('Update building sucessful! response:', data);
          toast.success('בקשה לעדכון מכשיר נשלחה בהצלחה');
        } catch (error) {
          console.error(error);
          toast.error('שגיאה בשדרוג מכשיר');
        
        }
      };

    return (
        <Fragment>
        <Header/>
        <BuildingHeader buildingData={buildingData} buildingId={buildingId} refreshData={() => {refreshData();}}/>
      {device ? 
        <Fragment>
          <Grid container spacing={2} maxWidth="md" justifyContent="center" alignItems="center"
            sx={{
              pt: 0,
              bgcolor: 'background.paper',
              boxShadow: 3,
              margin: '0 auto', // Center the grid container itself
              mt: '30px',
              mb:'30px',
              pb:2,
              maxWidth: {xs: '90%'},
            }}
          >
            <Grid item xs={12} sx={{backgroundColor: 'grey.200',display: 'flex', alignItems: 'center', justifyContent: 'space-around ', pb:2 }} >
                {device.devicetype==='base'?<Screenshot />:device.devicetype==="phone"?<PhoneIphone/>:device.devicetype==='guard'?<LocalPolice/>:<SmartScreen />}
                {device.devicetype==='phone'?'טלפון'
                :device.devicetype==='base'?'אינטרקום':device.devicetype=='guard'?'שומר':'מוניטור'} 
            </Grid>
            {device.devicetype==='phone' ? 
              <PhoneDevice device={device} onPhoneChanged={(p)=>handlePhoneChanged(p)} 
              edit={editMode} isAdmin={isAdmin} buildingId={buildingId} flatId={flatId}/>
              :
            <Fragment>
             <Grid item xs={4}> 
                <Typography>מזהה מכשיר</Typography>
            </Grid>
            <Grid item xs={8}> 
            {editMode && 
                    <TextField 
                    autoFocus
                    required
                    margin="dense"
                    id="name"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={displayName}
                    onChange={(e) => setDisplayName(e.target.value)}
                    />
             }
             {!editMode && device.displayName}
            </Grid>
             <Grid item xs={4}> 
                <Typography>מזהה מכשיר</Typography>
            </Grid>
            <Grid item xs={8}> 
                 <MacAddressInput value={deviceIdText} edit={false}/>
            </Grid>
            {device.devicetype==='base'?
              <Fragment>
                <Grid item xs={4}> 
                    <Typography>קוד פתיחה</Typography>
                </Grid>
                <Grid item xs={8}> 
                {editMode && 
                        <TextField
                        autoFocus
                  
                        required
                        margin="dense"
                        id="name"
                        label="קוד פתיחה"
                        type="text"
                        width="50px"
                        value={doorCode}
                        onChange={(e) => handleDoorCodeChange(e)}
                        helperText={doorCodeError?'קוד דלת נדרש להיות 4 ספרות':''}
                        error={doorCodeError}
                        variant="standard"
                        inputProps={{
                          maxLength: 4
                        }}
                        />
                    }
                    {!editMode && device.doorcode}
                </Grid>
              </Fragment>
              :<Fragment></Fragment>
            }
            </Fragment>
          }
          {isAdmin &&
            <Fragment>
              <Grid item xs={4}>ACS ID</Grid>
              <Grid item xs={8}>
                <Typography variant="caption" color="text.secondary" dir="ltr">
                  {device.ACSID}
                </Typography>
              </Grid>
            </Fragment>
          }
          
          {device.devicetype !== "phone" && 
          <Fragment>
            <Grid item xs={4}>סטטוס  חיבור</Grid>
            <Grid item xs={8}>
              <Typography color="text.secondary" dir="ltr">
                {gotDeviceStatus ? connected ? 'מחובר' : 'לא מחובר' : 'טוען...'}
              </Typography>
            </Grid>
          </Fragment>
          }
          {device.devicetype !== "phone" &&
          <Fragment>
            <Grid item xs={4}>גרסא</Grid>
            <Grid item xs={8}>
              <Typography color="text.secondary" dir="ltr">
                {gotDeviceStatus? appVersion ? appVersion : 'לא זמין': 'טוען...'}
              </Typography>
            </Grid>
          </Fragment>
          }
          

            <Grid item xs={12} alignItems="center">
              {(editMode || isAdmin || device.devicetype==='phone') && 
                <Button variant="contained" size="medium" startIcon={editMode?<Cancel /> : <Delete  />} sx={{ width: '120px', margin:'10px'}} onClick={confirmDelete}>
                  &nbsp; {deleteButtonText} &nbsp;
                </Button>
                }
              <Button variant="contained" size="medium" startIcon={editMode?<Send />:<Edit  />}  sx={{ width: '120px', margin:'10px' }} onClick={enterEditMode}> 
                &nbsp; {editButtonText} &nbsp;
              </Button>  
              {isAdmin && !editMode &&
              <Fragment>
              <Button variant="contained" size="medium" startIcon={<RestartAlt />} sx={{ width: '120px', margin:'10px'}} onClick={confirmRestart}>
                &nbsp; אתחול &nbsp;
              </Button>
              <Button variant="contained" size="medium" startIcon={<Upgrade />} sx={{ width: '120px', margin:'10px'}} onClick={confirmUpgrade}>
                &nbsp; שדרוג &nbsp;
              </Button></Fragment>
              }
               </Grid> 
            </Grid>
            {
              openDeleteDialog &&
                <Confirmation open={openDeleteDialog} title={"מחיקת מכשיר"} content={"האם למחוק את המכשיר " + deviceIdText + "?"} onConfirm={DeleteDevice} onCancel={cancelDelete}/>
            }
            {
              openRestartDialog &&
                <Confirmation open={openRestartDialog} title={"אתחול מכשיר"} content={ "אם לאתחל את המכשיר "+ deviceIdText + "?"} onConfirm={handleRestart} onCancel={cancelRestart}/>
            }
            {
              openUpgradeDialog &&
                <Confirmation open={openUpgradeDialog} title={"שדרוג מכשיר"} content={"האם לשדרג את המכשיר " + deviceIdText + "?"} onConfirm={handleUpgrade} onCancel={cancelUpgrade}/>
            }
            </Fragment>
    :        
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%', height: '80vh', alignItems:'center'}}>
    <TailSpin type="TailSpin" color="#00BFFF" height={80} width={80} />
  </div>

      }
        <Footer />
        </Fragment>
    );
      
    

}

export default DeviceInfo;