import React, { useState, Fragment, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../authentication/AuthProvider';
import Header from '../layout/Header';
import BuildingHeader from './BuildingHeader';
import { BlobServiceClient } from '@azure/storage-blob';
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { toast } from 'react-toastify';
import { Button, Typography, List, ListItem, ListItemText, Checkbox, Tooltip } from '@mui/material';
import StartIcon from '@mui/icons-material/Start';
import UploadIcon from '@mui/icons-material/Upload';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { grey, red, green } from '@mui/material/colors';
import useBuildingData from '../commands/useBuildingData';
import { TailSpin } from 'react-loader-spinner';

export default function Import() {
    const { buildingId } = useParams('') || {};//ensure tehre's a default value
    const appInsights = useAppInsightsContext();
    const track = useTrackEvent(appInsights, "ManagementApp");

    const [selectedFile, setSelectedFile] = useState(null);
    const [fileName, setFileName] = useState('');
    const { requestHeaders } = useContext(AuthContext);
      // Ref to the hidden file input element
    const hiddenFileInput = React.useRef(null);
    const [flatList, setFlatList] = useState([]);
    const [tenantsList, setTenantsList] = useState([]);
    const [processButtonDisabled, setProcessButtonDisabled] = useState(true);
    //record states
    const [flatCheckedState, setFlatCheckedState] = useState({});   // uncehcekd, checked, completed, exists or error
    const [tenantCheckedState, setTenantCheckedState] = useState({});    // uncehcekd, checked, completed, exists or error
    const [tenantErrors, setTenantErrors] = useState({});    
    const [flatErrors, setFlatErrors] = useState({});    
    //keep reference to buildingData to check if tenants already exist
    const [dataRefresh, setDataRefresh] = useState(false);
    const buildingData = useBuildingData(buildingId, dataRefresh);
    const [isBuildingDataLoaded, setIsBuildingDataLoaded] = useState(false);
    const [fileProcessing, setFileProcessing] = useState(false);    
    const [importingTenants, setImportingTenants] = useState(false);
    // Function to simulate click on file input when button is clicked
    const selectFile = event => {
        hiddenFileInput.current.click();
    };

    // useEffect to reset refreshFlag after triggering refresh
    useEffect(() => {
        if (dataRefresh) {
            setDataRefresh(false); // Reset the flag to false
            }
      }, [dataRefresh]);

      useEffect(() => {
        console.log('buildingData useEffect: ' + JSON.stringify(buildingData));
        setIsBuildingDataLoaded(buildingData !== null && buildingData !== undefined);
      }, [buildingData]);

    // processFile upates tenants list. when it completes, check which tenats arelady exist in the building
    useEffect(() => {
                    //update tenants list with existing tenants
                    console.log('tenantsList: ' + JSON.stringify(tenantsList));
                    tenantsList.forEach(tenant => {
                        const tenantExists = checkIfTenantExists(tenant.email);
                        console.log('tenantExists: ' + tenantExists + ' for tenant: ' + tenant.email);
                        if (tenantExists) {
                            handleTenantCheckboxChange(tenant.email, 'exists');
                        }
                    });
                    console.info("tenantsList: " + JSON.stringify(tenantsList));
    }, [tenantsList]);        

    useEffect(() => {
        console.log('flatList useEffect: ' + JSON.stringify(flatList));
        flatList.forEach(flat => {
            const flatChanged = checkIfFlatChanged(flat.flat_name, flat.family_name, flat.call_mode);
            console.log('flatUnchanged: ' + flatChanged + ' for flat: ' + flat.flat_name);
            if (!flatChanged) {
                handleFlatCheckboxChange(flat.flat_name, 'exists');
            }
        });
    }, [flatList]);
    
    const onFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
        setFileName(event.target.files[0].name);
        console.log(event.target.files[0]);
        clearLists();
    };

    const clearLists = () => {
        setProcessButtonDisabled(false);
        setFlatCheckedState({});
        setTenantCheckedState({});
        setFlatErrors({});
        setTenantErrors({});
    };
    
    const getSaSToken = async () => {
        try {
            const targetServer = process.env.REACT_APP_API_HOST;
            const api = `api/getImportSaSToken`;
    
            const response = await fetch(targetServer + api, {
                method: 'GET',
                headers: requestHeaders
            });
    
            if (!response.ok) {
                throw new Error(response.status + " - " + response.statusText);
            }
    
            const data = await response.text();
            return data;
        } catch (error) {
            throw new Error('בעיה בהעלאת קובץ: ' + error);
        }
    };

    const onFileUpload = async () => {
        if (!selectedFile) return;
        try{
            setProcessButtonDisabled(true);
            setFileProcessing(true);
            // Await the completion of getSaSToken and then access the response property
            const sasToken  = await getSaSToken(); 

            const storageAccountName = process.env.REACT_APP_STORAGE_ACCOUNT_NAME;
            console.log('storageAccountName: ' + storageAccountName);    
            const containerName = "import";
            const contentLength = selectedFile.size;
            console.info(`Uploading file ${selectedFile.name} with size ${contentLength} bytes to container ${containerName} in storage account ${storageAccountName} using SAS token ${sasToken}`);
            // Initialize Blob Service Client
            const blobService = new BlobServiceClient(`https://${storageAccountName}.blob.core.windows.net/?${sasToken}`);
        
            // Get the container
            const containerClient = blobService.getContainerClient(containerName);
        
            // Upload the file
            await containerClient.uploadBlockBlob(selectedFile.name, selectedFile, contentLength);
            await processFile();
            }
        catch (error)
        {
            console.error('בעיה בהעלאת קובץ: ' + error);
            track({severity: 'error', name: 'בעיה בהעלאת קובץ: ' + error});
            toast.error('בעיה בהעלאת קובץ: ' + error);
        }
        setProcessButtonDisabled(false);
        setFileProcessing(false);
    };

    const processFile = async () => {
        try {
            clearLists();
            const targetServer = process.env.REACT_APP_API_HOST;
            const api = `api/processUploadedFile?blob_name=` + selectedFile.name;
    
            const response = await fetch(targetServer + api, {
                method: 'GET',
                headers: requestHeaders
            });
    
            if (!response.ok) {
                throw new Error(response.status + " - " + response.statusText);
            }
    
            const data = await response.json();
            setFlatList(data.flats);
            setTenantsList(data.tenants);
            return data;
        } catch (error) {
            throw new Error('בעיה בהעלאת קובץ: ' + error);
        }

    };
    const updateTenant = async (flatName, name, phone, email) => {
        const targetServer = process.env.REACT_APP_API_HOST;
        const api = `api/building/${buildingId}/${flatName}/addtenant?useFlatName=true`;
        const tenant = {
            name: name,
            email: email,
            phone: phone
        };
        try{
          const response = await fetch(targetServer + api, {
            method: 'POST',
            headers: requestHeaders,
            body: JSON.stringify(tenant),
          });
          if (response.ok) {
            const data = await response.json();
            console.log('Tenant updated successfully! Response:', data);
          }
          else if (!response.ok) {
            const errorData = await response.text();
            let errorMessage = 'Failed to update Tennat! Error:' +  errorData;
            console.error(errorMessage);
            throw new Error(errorMessage);
          }
        }
        catch (error) {
          throw new Error(error);
        }
    };

    const updateFlat = (flatName, familyName, flatType) => {
        const targetServer = process.env.REACT_APP_API_HOST;
        const api = `api/building/${buildingId}/${flatName}?useFlatName=true`;
        
        let updatePayload = {};
        updatePayload.familyName = familyName;
        updatePayload.callMode = flatType;
    
          fetch(targetServer + api, {
            method: 'PUT',
            headers: requestHeaders,
            body: JSON.stringify(updatePayload),
          }).then(response=> {
          if (response.ok) {
            return response.json();
          }
          else if (!response.ok) {
            console.error('Failed to update flat! Response:', response.status + ' - ' + response.statusText);
            handleFlatCheckboxChange(flatName, 'error');
            console.error("error importing flat: " + response.statusText);
            addFlatError(flatName, response.statusText);

          }
        }).then(data => {
            console.log('Flat updated successfully! Response:', data);
            handleFlatCheckboxChange(flatName, 'completed');
        })
    }

    const importFlats = async () => {
        const checkedFlats = Object.entries(flatCheckedState).filter(([key, value]) => value ==='checked').map(([key]) => key);
        console.log(checkedFlats); 
        for(const flatId of checkedFlats){
            const flat = flatList.find(flat => flat.flat_name === flatId);
            if (flat) {
                updateFlat(flat.flat_name, flat.family_name, flat.call_mode);
            }
    }
    setDataRefresh(true);
}

const allTenantsTicked = () => {
    //check if all flats in flats list are checked, completed or errored
    return tenantsList.every(tenant => tenantCheckedState[tenant.email] === 'checked' || tenantCheckedState[tenant.email] === 'completed' || tenantCheckedState[tenant.email] === 'error' || tenantCheckedState[tenant.email] === 'exists');
};
const handleAllTenantsChanged = (event) => {
    const status = event.target.checked ? 'checked' : 'unchecked';
    console.log('handleAllTenantsChanged, status: ' + status);
    if(status==='checked'){
        //find all flats that are not already checked, errored or completed
        const uncheckedTenants = tenantsList.filter(tenant => tenantCheckedState[tenant.email] !== 'checked' && tenantCheckedState[tenant.email] !== 'error' && tenantCheckedState[tenant.email] !== 'completed' && tenantCheckedState[tenant.email] !== 'exists');    
        //change all to 'checked'
        uncheckedTenants.forEach(tenant => {
            console.log('tenant: ' + tenant.email + ' status: ' + tenantCheckedState[tenant.email]);
            handleTenantCheckboxChange(tenant.email, 'checked');
        });
    }
    if(status==='unchecked')
    {
        const checkedTenants = tenantsList.filter(tenant => tenantCheckedState[tenant.email] === 'checked');
        checkedTenants.forEach(tenant => {
            handleTenantCheckboxChange(tenant.email, 'unchecked');
        });
    }
};
    const allFlatsTicked = () => {
        //check if all flats in flats list are checked, completed or errored
        return flatList.every(flat => flatCheckedState[flat.flat_name] === 'checked' || flatCheckedState[flat.flat_name] === 'completed' || flatCheckedState[flat.flat_name] === 'error' || flatCheckedState[flat.flat_name] === 'exists');
    };
    const handleAllFlatsChanged = (event) => {
        const status = event.target.checked ? 'checked' : 'unchecked';
        console.log('handleAllFlatsChanged, status: ' + status);
        if(status==='checked'){
            //find all flats that are not already checked, errored or completed
            const uncheckedFlats = flatList.filter(flat => flatCheckedState[flat.flat_name] !== 'checked' && flatCheckedState[flat.flat_name] !== 'error' && flatCheckedState[flat.flat_name] !== 'completed' && flatCheckedState[flat.flat_name] !== 'exists');    
            //change all to 'checked'
            uncheckedFlats.forEach(flat => {
                console.log('flat: ' + flat.flat_name + ' status: ' + flatCheckedState[flat.flat_name]);
                handleFlatCheckboxChange(flat.flat_name, 'checked');
            });
        }
        if(status==='unchecked')
        {
            const checkedFlats = flatList.filter(flat => flatCheckedState[flat.flat_name] === 'checked');
            checkedFlats.forEach(flat => {
                handleFlatCheckboxChange(flat.flat_name, 'unchecked');
            });
        }
    };
    
    const importTenants = async () => {
        setImportingTenants(true);
        const checkedTenants = Object.entries(tenantCheckedState).filter(([key, value]) => value === 'checked').map(([key]) => key);
        console.log(checkedTenants); 
        checkedTenants.forEach(email => {
            const tenant = tenantsList.find(tenant => tenant.email === email);
            if (tenant) {
                    updateTenant(tenant.flat_name, tenant.tenant_name, tenant.phone, tenant.email)
                    .then(response => {
                        handleTenantCheckboxChange(tenant.email, 'completed');
                        })
                    .catch(error => {
                        handleTenantCheckboxChange(tenant.email, 'error');
                        console.error("error importing tenant: " + error);
                        addTenantError(tenant.email, error);
                    });
                }
            });
        setImportingTenants(false);
        setDataRefresh(true);
    }

    const handleFlatCheckboxChange = (flatId, status) => {
        setFlatCheckedState(prevState => {
            const currentState = prevState[flatId];
    
            // If already completed, do not allow any change to state
            if (currentState === 'completed') {
                return prevState;
            }
    
            // Update the state based on the event
            return {
                ...prevState,
                [flatId]: status
            };
        });
    };

    const addFlatError = (flatId, error) => {
        setFlatErrors(prevState => {
            return {
                ...prevState,
                [flatId]: error
            };
        });
    };

    const addTenantError = (email, error) => {
        setTenantErrors(prevState => {
            return {
                ...prevState,
                [email]: error
            };
        });
    }; 
    const handleTenantCheckboxChange = (tenantId, status) => {
        setTenantCheckedState(prevState => {
            const currentState = prevState[tenantId];
    
            // If already completed, do not allow any change to state
            if (currentState === 'completed') {
                return prevState;
            }
    
            // Update the state based on the event
            return {
                ...prevState,
                [tenantId]: status
            };
        });
    };

    const getFlatStatusElement = (flatId) => {
        switch(flatCheckedState[flatId]) {
            case 'completed':
                return <CheckCircleIcon sx={{color: green[500], margin: '10px'}} />
            case 'error':
                return <Tooltip title={String(flatErrors[flatId])}><ErrorIcon sx={{color: red[500], margin: '10px'}} /></Tooltip>
                case 'exists':
                    return <Tooltip title='פרטי דירה לא השתנו'><CheckCircleIcon sx={{color: grey[500], margin: '10px'}}/></Tooltip>
                default:
                return <Checkbox 
                checked={flatCheckedState[flatId] === 'checked' || false}
                onChange={(event) => handleFlatCheckboxChange( flatId, event.target.checked?'checked':'unchecked')}
            />    
        }
    };

    const checkIfFlatChanged = (flatName, familyName, flatType ) => {
        console.log('flatName: ' + flatName + ' familyName: ' + familyName + ' flatType: ' + flatType);
        if (buildingData.flats) {
            for (const flat of buildingData.flats) {
                if (flat.flatname === flatName) {
                    if (flat.familyName !== familyName || flat.callMode !== flatType) {
                        console.log('flatId: ' + flatName + ' needs updating');
                        return true;
                    }
                }
            }
        }
        console.log('flat ' + flatName + ' does not need updating');
        return false;
    };
    const checkIfTenantExists = (tenantId) => {
        if (buildingData.flats) {
            for (const flat of buildingData.flats) {
                if (flat.devices && flat.devices.find(device => device.email === tenantId)) {
                    console.log('tenantId: ' + tenantId + ' exists');
                    return true;
                }
            }
        }
        console.log('tenantId: ' + tenantId + ' does not exist');
        return false;
    }
    const getTenantStatusElement = (tenantId) => {
        console.log('tenantId: ' + tenantId + ' status: ' + tenantCheckedState[tenantId]);
        switch(tenantCheckedState[tenantId]) {
            case 'completed':
                return <CheckCircleIcon sx={{color: green[500], margin: '10px'}} />
            case 'error':
                return <Tooltip title={String(tenantErrors[tenantId])}><ErrorIcon sx={{color: red[500], margin: '10px'}} /></Tooltip>
            case 'exists':
                return <Tooltip title='דייר כבר קיים בבנין זה'><CheckCircleIcon sx={{color: grey[500], margin: '10px'}}/></Tooltip>

            default:
                return <Checkbox 
                checked={tenantCheckedState[tenantId] === 'checked' || false}
                onChange={(event) => handleTenantCheckboxChange(tenantId,event.target.checked?'checked':'unchecked')}
            />    
        }
    };

    const isImportTenantsDisabled = () => {
        console.log('isImportTenantsDisabled: ' + importingTenants);
        return Object.values(tenantCheckedState).every(value => value !== 'checked') || importingTenants===true;
    }

    const isImportFlatsEnabled = () => {
        //check if tenantsCheckedState contains any 'checked' values
        console.log('flatCheckedState: ' + JSON.stringify(flatCheckedState));
        return Object.values(flatCheckedState).some(value => value === 'checked');
    }
      return (
        <Fragment>
            <Header />
            <BuildingHeader buildingId={buildingId} />

            <div className="position-absolute top-30 start-0 w-100 d-flex flex-column justify-content-center align-items-center">
            <List className='list-container'
                sx={{
                pt: 0,
                bgcolor: 'background.paper',
                boxShadow: 3,
                minWidth: 700,
                marginBottom: 2,
                marginTop: 2
                }}>
                <ListItem sx={{backgroundColor:"lightblue"}}>
                    <div className="d-flex flex-row justify-content-center align-items-center w-100">
                    <Typography variant="h4" color="GrayText"> ייבוא דיירים</Typography>
                    </div>
                </ListItem>
                <ListItem >
                    <ListItemText sx={{textAlign:"right", width:"150px"}}>
                        <input type="file" onChange={onFileChange} style={{display:'none'}} ref={hiddenFileInput}/>
                        <Button variant="contained" color="primary"  onClick={selectFile} sx={{width:'140px'}} >בחר/י קובץ &nbsp; <UploadIcon /></Button><br />
                    </ListItemText>
                    <ListItemText>   
                        <Typography>{fileName}</Typography>
                    </ListItemText>
                </ListItem>
                <ListItem >
                    <ListItemText sx={{textAlign:"right", width:"200px"}}>
                        <Button variant="contained" color="primary"  onClick={onFileUpload} sx={{width:'140px'}} disabled={processButtonDisabled || isBuildingDataLoaded===false}>קרא/י קובץ &nbsp; <StartIcon /></Button>
                    </ListItemText>
                </ListItem>
                </List>

            {fileProcessing && <TailSpin type="TailSpin" color="#00BFFF" height={80} width={80} />}
                {flatList.length>0 && !fileProcessing &&
            <List className='list-container'
                sx={{
                pt: 0,
                bgcolor: 'background.paper',
                boxShadow: 3,
                minWidth: 700,
                marginBottom: 2
                }}>
                    <ListItem sx={{backgroundColor:'lightblue'}}>
                        <Checkbox checked={allFlatsTicked()} onChange={handleAllFlatsChanged}/>

                        <ListItemText sx={{textAlign:"center"}}>
                            <Typography variant='h5' color={'GrayText'}>רשימת דירות</Typography>
                        </ListItemText>
                    </ListItem>
                    {flatList.map((flat, index) => (
                        <ListItem key={`flat-${index}`}  sx={{
                            backgroundColor: index % 2 === 0 ? 'white' : 'lightgrey', // Corrected line
                        }}>
                              {getFlatStatusElement(flat.flat_name)}
                              
                            <ListItemText sx={{textAlign:"start", width: "50px"}}>
                                <Typography variant='h6' color={'GrayText'} sx={{ paddingRight: 2 }}>{flat.flat_name}</Typography>
                            </ListItemText>
                            <ListItemText sx={{textAlign:"start"}}>
                                <Typography variant='h6' color={'GrayText'}>{flat.family_name}</Typography>
                            </ListItemText>
                            <ListItemText sx={{textAlign:"start"}}>
                                <Typography variant='h6' color={'GrayText'}>{flat.call_mode}</Typography>
                            </ListItemText>
                        </ListItem>
                    ))}
                    <ListItem>
                        <Button variant="contained" color="primary" sx={{width:'140px'}} onClick={importFlats} disabled={!isImportFlatsEnabled()}>יבוא דירות</Button>
                    </ListItem>
                </List>}
                {tenantsList.length>0 && !fileProcessing &&
                <List className='list-container'
                            sx={{
                            pt: 0,
                            bgcolor: 'background.paper',
                            boxShadow: 3,
                            minWidth: 700,
                            marginBottom: 2
                            }}>
                    <ListItem sx={{backgroundColor:"lightblue"}}>
                    <Checkbox checked={allTenantsTicked()} onChange={handleAllTenantsChanged}/>
                    <ListItemText sx={{textAlign:"center"}}>
                            <Typography variant='h5' color={'GrayText'}>רשימת דיירים</Typography>
                        </ListItemText>
                    </ListItem>
                    {tenantsList.map((tenant, index) => (
                        <ListItem key={`tenant-${index}`}
                        sx={{
                            backgroundColor: index % 2 === 0 ? 'white' : 'lightgrey', // Corrected line
                        }}>
                            {getTenantStatusElement(tenant.email)}
                            <ListItemText sx={{textAlign:"start"}}>
                                <Typography variant='h6' color={'GrayText'} sx={{ paddingRight: 2 }}>{tenant.flat_name}</Typography>
                            </ListItemText>
                            <ListItemText sx={{textAlign:"start"}}>
                                <Typography variant='h6' color={'GrayText'}>{tenant.tenant_name}</Typography>
                            </ListItemText>
                            <ListItemText sx={{textAlign:"start"}}>
                                <Typography variant='h6' color={'GrayText'}>{tenant.phone} </Typography>
                            </ListItemText>
                            <ListItemText sx={{textAlign:"start"}}>
                                <Typography variant='h6' color={'GrayText'}>{tenant.email} </Typography>
                            </ListItemText>
                        </ListItem>
                    ))}
                    <ListItem>
                        <Button variant="contained" color="primary" sx={{width:'140px'}} onClick={importTenants} disabled={isImportTenantsDisabled()}>יבוא דיירים</Button>
                    </ListItem>
                </List>
                }
            </div>
        </Fragment>
    );
}