import React , {useContext, useState, useEffect,Fragment} from 'react'
import { AuthContext } from '../authentication/AuthProvider';
import { ListItemText, List, ListItem, ListItemIcon,Divider, Container, Typography, Button, Grid} from '@mui/material';
import { SmartScreen, LocalPolice, Screenshot,PhoneIphone,Refresh, Link, LinkOff} from '@mui/icons-material';
import Header from 'components/layout/Header';
import Footer from '../layout/Footer';
import {TailSpin} from "react-loader-spinner";
import useDevicesList from '../commands/useDevicesList';
import FilterList from 'components/buttons/FilterList';
import { useNavigate } from 'react-router-dom';

function Devices(){

    const { requestHeaders, isAdmin} = useContext(AuthContext);
    const { devicesList, fetchDevicesList } = useDevicesList();
    
    const [filteredList, setFilteredList] = useState(null);
      
    const navigate = useNavigate();

    // exectue when buildingsList is upadated (when hook completes)
    useEffect(() => {
      if (devicesList !== null && devicesList !== undefined) {
        setFilteredList(devicesList);
       }
      }, [devicesList]);

      const dataFiltered = (filtered) => {
        setFilteredList(filtered);
     }

      const refreshList = () => {
        fetchDevicesList(null,true); // Force refresh
      };
  
      const handleDeviceClick = (e, buildingId, flatId, deviceId) => {
        navigate(`/building/`+buildingId + "/"+flatId+"/" + deviceId);
    };   
    return(
      <Fragment> 
      <Header/>
      <Container maxWidth="md" sx={{pt:2}}>
    
        {filteredList ? 
        <div className='d-flex flex-column'>
          <Fragment>
          <List 
        sx={{
          pt: 0,
          bgcolor: 'background.paper',
          boxShadow: 3,
          overflowY: 'auto',
          
        }}>
          <ListItem sx={{backgroundColor:"grey.200"}} key="header1">
          <div className="d-flex flex-row justify-content-center align-items-center w-100">
            <Typography variant='h4' color="GrayText">רשימת מכשירים</Typography>
          </div>
          </ListItem>
          <ListItem alignItems='flex-start' key="header2">
            <Grid container spacing={2} justifyContent="space-between" alignItems='center'>
              <Grid item xs={6}> 
                <FilterList data={devicesList} onFilter={dataFiltered} fields="['title', 'deviceid', 'devicetype','ACSID','displayName','connectionState','appVersion']"/>
              </Grid>
              <Grid item xs={6} container justifyContent="flex-end">
                <Button variant="contained" size="medium" startIcon={<Refresh />} onClick={refreshList} >&nbsp; רענון רשימה &nbsp;</Button>
              </Grid>
            </Grid>
            </ListItem>
          <ListItem key="header3">
            <ListItemText sx={{textAlign:"start", fontWeight: 'bold',flex: '1 1 5%'}}><Typography  fontWeight="bold" >סוג </Typography></ListItemText>
            <ListItemText sx={{textAlign:"start",fontWeight: 'bold',flex: '1 1 25%'}} ><Typography  fontWeight="bold" > בניין</Typography></ListItemText>
            <ListItemText sx={{textAlign:"start",fontWeight: 'bold',flex: '1 1 25%'}}><Typography fontWeight="bold" >דירה</Typography></ListItemText>
            <ListItemText sx={{textAlign:"start",fontWeight: 'bold',flex: '1 1 25%'}}><Typography fontWeight="bold" >שם מכשיר</Typography></ListItemText>
            <ListItemText sx={{textAlign:"start",fontWeight: 'bold',flex: '1 1 10%'}}><Typography fontWeight="bold" >גרסה</Typography></ListItemText>
            <ListItemText sx={{textAlign:"start",fontWeight: 'bold',flex: '1 1 10%'}}><Typography fontWeight="bold" >סטטוס</Typography></ListItemText>
          </ListItem>
          </List>

        <List 
 sx={{
  pt: 0,
  bgcolor: 'background.paper',
  boxShadow: 3,
  mb:1,
  maxHeight: {
    xs:'500px',
    sm: '550px',
    md:'550px',
    lg:'550px',
    xl:'600px'
  },
  overflowY: 'auto'

}}  >
          {filteredList && filteredList.map((device) => (
            <div>
              <Divider variant="fullwidth" component="li" />
              <ListItem  key={device.deviceid} onClick={(e)=>{handleDeviceClick(e,device.buildingid, device.flatid, device.deviceid)}}
               sx={{
                '&:hover': {
                  backgroundColor: 'grey.200' // change this to your preferred color
                },
                display: 'flex',
                justifyContent: 'right',
                alignItems: 'right',
                textAlign: 'right',
              }}>
                <ListItemIcon>
                        {
                            device.devicetype === 'monitor' ? <SmartScreen/> :
                            device.devicetype === 'guard' ? <LocalPolice/> :
                            device.devicetype === 'base' ? <Screenshot/> :
                            device.devicetype === 'phone' ? <PhoneIphone/> :
                            null
                        }
                </ListItemIcon>
                <ListItemText  primary={device.title}   sx={{ flex: '1 1 20%' }} />
                <ListItemText secondary={device.flatname}  sx={{ flex: '1 1 20%' }}  />
                <ListItemText secondary={device.displayName}   sx={{ flex: '1 1 20%' }} />
                <ListItemText  sx={{ flex: '1 1 10%' }} >
                  {device.appVersion}
                </ListItemText>
                <ListItemIcon sx={{ minWidth: '40px' }}>
                  {
                    device.connectionState === undefined ? '' :
                    device.connectionState === 'Connected' ? <Link/> : <LinkOff/>} 
                </ListItemIcon>
              </ListItem>
            </div>
    
              ))}
        </List>
        </Fragment>
        <Footer/>
        </div>
          : (
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', height: '80vh', alignItems:'center'}}>
            <TailSpin type="TailSpin" color="#00BFFF" height={80} width={80} />
          </div>
            )}
        </Container>
        </Fragment>
      );

}

export default Devices;